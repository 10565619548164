import React, { useState } from 'react';
import {
  Box, Image, Badge, Text, VStack, HStack, Divider, Button,
  SimpleGrid, GridItem, Icon, useDisclosure, Modal, ModalOverlay,
  ModalContent, ModalHeader, ModalCloseButton, ModalBody
} from '@chakra-ui/react';
import { CheckIcon, StarIcon, CalendarIcon } from '@chakra-ui/icons';

const ListingPage: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [fullScreenImage, setFullScreenImage] = useState<string | null>(null);
  
  // Sample images for demonstration
  const images = Array.from({ length: 28 }, (_, i) => `/photos/${i + 1}.webp`);

  const handleImageClick = (image: string) => {
    setSelectedImage(image);
    setFullScreenImage(image);  // Set the image to be shown in full screen in modal
    onOpen();
  };

  const handleModalImageClick = (image: string) => {
    setFullScreenImage(image);
  };

  return (
    <Box p={4} position="relative">
      <VStack spacing={4} align="stretch">
        <HStack spacing={4} position="relative">
          <Image borderRadius="md" src={images[0]} alt="House" />
          <SimpleGrid columns={2} spacing={4} flex="1">
            {images.slice(1, 5).map((image, index) => (
              <GridItem key={index} onClick={() => handleImageClick(image)}>
                <Image borderRadius="md" src={image} alt={`Photo ${index + 1}`} cursor="pointer" />
              </GridItem>
            ))}
          </SimpleGrid>
          <Button
            onClick={onOpen}
            colorScheme="red"
            variant="outline"
            position="absolute"
            bottom="4"
            right="4"
          >
            Show All Photos
          </Button>
        </HStack>
        <Box>
          <Text fontSize="2xl" fontWeight="bold">New Home with Yard | Walk to SoCo</Text>
          <Text fontSize="lg">Entire home in Austin, Texas, United States</Text>
          <HStack spacing={2}>
            <Badge colorScheme="red">Guest favorite</Badge>
            <Text fontSize="md">One of the most loved homes on JairbnbE, according to guests</Text>
          </HStack>
          <HStack spacing={2}>
            <Icon as={StarIcon} color="red.500" />
            <Text fontSize="md">5.0</Text>
            <Text fontSize="md">(19 Reviews)</Text>
          </HStack>
        </Box>
        <Divider />
        <Box>
          <HStack spacing={4}>
            <Icon as={CalendarIcon} />
            <VStack align="start" spacing={0}>
              <Text fontSize="md">Check-in</Text>
              <Text fontSize="lg">09/07/2024</Text>
            </VStack>
            <Icon as={CalendarIcon} />
            <VStack align="start" spacing={0}>
              <Text fontSize="md">Check-out</Text>
              <Text fontSize="lg">17/07/2024</Text>
            </VStack>
          </HStack>
        </Box>
        <Box>
          <Button colorScheme="red" width="full">Change dates</Button>
        </Box>
        <Divider />
        <Box>
          <Text fontSize="lg" fontWeight="bold">What this place offers</Text>
          <SimpleGrid columns={2} spacing={4}>
            <HStack>
              <CheckIcon color="red.500" />
              <Text>Garden view</Text>
            </HStack>
            <HStack>
              <CheckIcon color="red.500" />
              <Text>Wifi</Text>
            </HStack>
            <HStack>
              <CheckIcon color="red.500" />
              <Text>TV</Text>
            </HStack>
            <HStack>
              <CheckIcon color="red.500" />
              <Text>Free dryer - In unit</Text>
            </HStack>
            <HStack>
              <CheckIcon color="red.500" />
              <Text>Bath</Text>
            </HStack>
            <HStack>
              <CheckIcon color="red.500" />
              <Text>Kitchen</Text>
            </HStack>
            <HStack>
              <CheckIcon color="red.500" />
              <Text>Free parking on premises</Text>
            </HStack>
            <HStack>
              <CheckIcon color="red.500" />
              <Text>Free washer - In unit</Text>
            </HStack>
            <HStack>
              <CheckIcon color="red.500" />
              <Text>Central air conditioning</Text>
            </HStack>
            <HStack>
              <CheckIcon color="red.500" />
              <Text>Exterior security cameras on property</Text>
            </HStack>
          </SimpleGrid>
        </Box>
      </VStack>

      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>All Photos</ModalHeader>
          <ModalCloseButton />
          <ModalBody p={0}>
            <SimpleGrid columns={[1, 2, 3]} spacing={4}>
              {images.map((image, index) => (
                <Box key={index} onClick={() => handleModalImageClick(image)}>
                  <Image borderRadius="md" src={image} alt={`Photo ${index + 1}`} cursor="pointer" />
                </Box>
              ))}
            </SimpleGrid>
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* Fullscreen Image Modal */}
      <Modal isOpen={!!fullScreenImage} onClose={() => setFullScreenImage(null)} size="full">
        <ModalOverlay />
        <ModalContent p={0}>
          <ModalCloseButton />
          <ModalBody p={0}>
            {fullScreenImage && (
              <Image src={fullScreenImage} alt="Full Screen" width="100%" height="100%" objectFit="contain" />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ListingPage;
