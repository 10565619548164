import React, { useState } from 'react';
import { Box, Input, Button, VStack, Text } from '@chakra-ui/react';

interface LoginProps {
  onLogin: () => void;
}

const Login: React.FC<LoginProps> = ({ onLogin }) => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = () => {
    if (password === 'helloworld') {
      onLogin();
    } else {
      setError('Incorrect password');
    }
  };

  return (
    <VStack spacing={4} align="center" mt={20}>
      <Text fontSize="2xl">Enter Password to Access</Text>
      <Input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      {error && <Text color="red.500">{error}</Text>}
      <Button colorScheme="teal" onClick={handleLogin}>
        Submit
      </Button>
    </VStack>
  );
};

export default Login;
