import React from 'react';
import { Box, Flex, Heading, Spacer, Button, Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';

const Header: React.FC = () => {
  return (
    <Box bg="white" boxShadow="md" p={4}>
      <Flex alignItems="center">
        <img src="/jairbne.svg" alt="JairbnE" width={32} />
        <Heading size="md" color={"red.500"}>JairbnE</Heading>
        <Spacer />
        {/* <InputGroup maxW="300px" mr={4}>
          <InputLeftElement pointerEvents="none">
            <SearchIcon color="gray.300" />
          </InputLeftElement> */}
          {/* <Input type="search" placeholder="Search" /> */}
        {/* </InputGroup> */}
        {/* <Button colorScheme="teal" variant="outline">Log In</Button> */}
      </Flex>
    </Box>
  );
};

export default Header;
