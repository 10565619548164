import React, { useState } from 'react';
import { Box } from '@chakra-ui/react';
import Header from './components/Header';
import ListingPage from './components/ListingPage';
import Login from './components/Login';

const App: React.FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  return (
    <Box>
      {!isLoggedIn ? (
        <Login onLogin={handleLogin} />
      ) : (
        <>
          <Header />
          <ListingPage />
        </>
      )}
    </Box>
  );
};

export default App;
